// eslint-disable-next-line no-redeclare,no-unused-vars
function administrateDetail(param) {
  var view = param.view;
  var object = param.object;
  var objectSub = param.objectSub;
  var formId = view.formId;
  if (!formId) formId = 'form';
  if (!parameter[object].labels) parameter[object].labels = {};

  // param settings
  administrateObject(param, object);

  // create individual form
  if (!param.updating)
    $(
      '<button class="btn btn-light"><i class="far fa-layer-plus fa-fw"></i></button>'
    )
      .prependTo(view.buttonsRight)
      .on(click, function () {
        var name = prompt('Name der Sicht (englisch)', ucfirst(objectSub));
        if (!name) return;
        name = name.toLowerCase();
        var formIdNew = 'form' + ucfirst(name);
        if (parameter[object][formIdNew]) {
          showInfo('Name bereits vergeben', 'warning');
          return;
        }
        formId = formIdNew;
        objectSub = name;
        var value = getObjectValue(parameter, [object, 'form'], '');
        value = value.join('');
        saveAdminSettings(
          {type: 'option', object: object, key: formId, value: value},
          function () {
            if (objectSub != param.objectSub) param.objectSub = objectSub;
            view.update({updateAll: true});

            if (!parameter[object].subs) parameter[object].subs = [''];
            parameter[object].subs.push(objectSub);
            saveAdminSettings({
              type: 'option',
              object: object,
              key: 'subs',
              value: parameter[object].subs,
            });
          }
        );
      })
      .addTooltip('Neue Sicht anlegen');

  // load other form
  // if (objectSub && formId != 'form') {
  // 	var values = [];
  // 	var labels = [];
  // 	$.each(parameter[object], function (option, value) {
  // 		if (option.indexOf('form') === 0 && option != formId) {
  // 			values.push(option);
  // 			labels.push(option + ' (' + parameter[object]['label' + option.slice(4)] + ')');
  // 		}
  // 	});
  // 	if (values.length)
  // 		buildFormLine(left, {
  // 			label: 'Sicht überspielen',
  // 			field: 'select',
  // 			values: values,
  // 			labels: labels,
  // 			save: function (option) {
  // 				var value = parameter[object][option].join('');
  // 				saveAdminSettings({type: 'option', object: object, key: formId, value: value}, view);
  // 				parameter[object][formId] = value.split('');
  // 				view.update();
  // 			}
  // 		});
  // }

  // missing fields
  if (!param.updating && view.right)
    $(
      '<button class="btn btn-light"><i class="far fa-eye-slash fa-fw"></i></button>'
    )
      .prependTo(view.buttonsRight)
      .on(click, function () {
        var cont = view.right.children('.missing-fields');
        if (cont[0]) cont.toggleClass('d-none');
        else {
          cont = $('<div class="card missing-fields"></div>').appendTo(
            view.right
          );
          var list = $(
            '<ul class="list-group list-group-flush"></ul>'
          ).appendTo(cont);
          $.each(fields[object], function (key) {
            if (!view.body.find('[key=' + key + ']')[0])
              buildFormLine(
                list,
                Object.assign({}, param, {sidebar: true}),
                key
              );
          });
          edit();
        }
      })
      .addTooltip('ausgeblendete Felder');

  // save form
  view.formSave = function (update) {
    var form = [];
    // var saveBlockColors = false;

    view.find('.card').each(function () {
      var card = $(this);
      // if (!card.find('[key]')[0]) return;

      // add block
      if (!card.parent().hasClass('view-right')) {
        var block = card.attr('ident');
        if (!block) block = 'block' + rand();
        parameter[object].labels[block] = card.find('.card-title').text();
        if (card.prev().is('.view-body'))
          form.push('*' + block); // sticky
        else form.push('-' + block);
      }

      // add sub tabs
      if (card.children('.subtab')[0]) {
        var subtabs = card
          .find('.subtab > .button')
          .map(function () {
            return $(this).text();
          })
          .get();
        $(subtabs).each(function (i, title) {
          form.push('[' + title);
          card
            .children('[data-site=left]')
            .eq(i)
            .children('.form-group')
            .each(function () {
              if ($(this).attr('key')) form.push(':' + $(this).attr('key'));
            });
          card
            .children('[data-site=right]')
            .eq(i)
            .children('.form-group')
            .each(function () {
              if ($(this).attr('key'))
                form.push(':' + $(this).attr('key') + '>');
            });
        });
      }

      // add sidebar fields
      else if (
        card.parent().hasClass('view-right') &&
        !card.hasClass('missing-fields')
      ) {
        card.find('.list-group-item,.form-group').each(function () {
          if ($(this).attr('key')) form.push('<' + $(this).attr('key'));
        });
      }

      // add normal fields
      else {
        card
          .find('[data-site=left].col-xl-6 .form-group:not(.form-group-table)')
          .each(function () {
            if ($(this).attr('key')) form.push(':' + $(this).attr('key'));
          });
        card
          .find('[data-site=right].col-xl-6 .form-group:not(.form-group-table)')
          .each(function () {
            if ($(this).attr('key')) form.push(':' + $(this).attr('key') + '>');
          });
        card
          .find('[data-site=left].col-12 .form-group:not(.form-group-table)')
          .each(function () {
            if ($(this).attr('key')) form.push(':' + $(this).attr('key'));
          });
      }
    });
    if (view.modal)
      $.each(parameter[object][formId], function (i, k) {
        if (str_starts_with(k, '<')) form.push(k);
      });

    parameter[object][formId] = form;
    var callback;
    if (update)
      callback = function () {
        view.update();
      };

    // clear labels
    if (parameter[object].labels)
      $.each(parameter[object].labels, function (key) {
        var found = false;
        $.each(parameter[object], function (option, value) {
          if (
            option.indexOf('form') === 0 &&
            (inArray('-' + key, value) || inArray('*' + key, value))
          ) {
            found = true;
            return false;
          }
        });
        if (!found) delete parameter[object].labels[key];
      });

    saveAdminSettings(
      {
        type: 'option',
        object: object,
        key: formId,
        value: form.join(''),
        key2: 'labels',
        value2: parameter[object].labels,
      },
      callback
    );
  };
  if (view.sidebar)
    buildFormLine(view.sidebar, {
      field: 'read',
      value: object + (objectSub ? ' :' + objectSub : '') + ' :' + formId,
    }).addClass('admin-info');

  // 		var tab = $(this).children('a').text().replace('-','&#x2D;').replace(/\([0-9]+\)/g,'').trim();
  // 		form.push('|'+tab);
  // 		view.find('#'+$(this).attr('aria-controls')+' .box').each(function(){
  // 			form.push('-'+$(this).children('.title').text().replace('-','&#x2D;').trim());
  // 			$(this).find('[data-site=left] > .line').each(function(){ if ($(this).attr('key')) form.push(':'+$(this).attr('key')); })
  // 			$(this).find('[data-site=right] > .line').each(function(){ if ($(this).attr('key')) form.push(':'+$(this).attr('key')+'>'); })
  // 		});
  // 	});

  // var form = [];
  // $.each(view.lineCreator,function(tab,lines){ $.each(lines,function(i,createLine){ createLine(tab); }); delete view.lineCreator[tab]; }); // first create all lines
  // setTimeout(function(){
  // 	view.find('[role=tab]:not(.hiddenTab)').each(function(){
  // 		var tab = $(this).children('a').text().replace('-','&#x2D;').replace(/\([0-9]+\)/g,'').trim();
  // 		form.push('|'+tab);
  // 		view.find('#'+$(this).attr('aria-controls')+' .box').each(function(){
  // 			form.push('-'+$(this).children('.title').text().replace('-','&#x2D;').trim());
  // 			$(this).find('[data-site=left] > .line').each(function(){ if ($(this).attr('key')) form.push(':'+$(this).attr('key')); })
  // 			$(this).find('[data-site=right] > .line').each(function(){ if ($(this).attr('key')) form.push(':'+$(this).attr('key')+'>'); })
  // 		});
  // 	});
  // 	view.children('.sticky').each(function(){ form.push('*'+$(this).attr('key')); });
  // 	if (!form.length){ showInfo('Fehler beim speichern',true); return; }
  // 	saveAdminSettings({type:'option',object:object,key:formId,value:form.join('')},view);
  // 	parameter[object][formId] = form;
  // },1000);
  // var li = $('<div class="line"><label>Feldanordnung speichern</label></div>').appendTo(right);
  // $('<span class="button"><i class="fal fa-save"></i></span>').appendTo(li).on(click,view.formSave);

  // edit form
  function edit() {
    // field, block, tab draggable
    var saved = false;
    var indexOrig;
    view.formSortable = function () {
      var pm1 = {
        handle: 'label',
        connectWith: view.find(
          '[data-site=left],[data-site=right],.view-right .list-group'
        ),
        placeholder: 'mb-5 bg-warning rounded-1',
        forcePlaceholderSize: true,
        tolerance: 'pointer',
        cursor: 'move',
        helper: function (evt, line) {
          return line.clone().appendTo(view);
        },
      };
      pm1.sort = function () {};
      pm1.start = function () {
        saved = false;
      };
      pm1.stop = function () {};
      pm1.update = function (evt, line) {
        if (saved) return;

        line = $(line.item);
        var sidebar = line.parents('.view-right')[0];
        if (sidebar && line.hasClass('form-group'))
          line.removeClass('form-group').addClass('list-group-item');
        else if (!sidebar && line.hasClass('list-group-item'))
          line.removeClass('list-group-item').addClass('form-group');
        view.formSave();
        saved = true;
      };
      view
        .find(
          '[data-site=left],[data-site=right],.view-right .list-group,.split-line'
        )
        .sortable(pm1);

      // sub tabs
      var pm2 = {
        tolerance: 'pointer',
        cursor: 'move',
        containment: 'parent',
      };
      pm2.start = function (evt, ui) {
        indexOrig = ui.item.prevAll('.button').length;
      };
      pm2.sort = function () {};
      pm2.stop = function () {};
      pm2.update = function (evt, ui) {
        var subtab = ui.item.parent();
        var left = subtab.nextAll('[data-site=left]').eq(indexOrig).detach();
        var right = subtab.nextAll('[data-site=right]').eq(indexOrig).detach();
        var index = ui.item.prevAll('.button').length;
        var after = subtab;
        if (index) after = subtab.nextAll('[data-site=right]').eq(index - 1);
        left.insertAfter(after);
        right.insertAfter(left);
        view.formSave();
      };
      view.find('.subtab').sortable(pm2);

      // cards
      var pm3 = {
        handle: '.card-title',
        placeholder: 'mb-5 bg-warning rounded-1',
        forcePlaceholderSize: true,
        tolerance: 'pointer',
        cursor: 'move',
        helper: function (evt, box) {
          return box.clone().appendTo(view);
        },
      };
      pm3.sort = function () {};
      pm3.stop = function () {};
      pm3.update = function (evt, ui) {
        var blockKey = ui.item.attr('key');
        parameter[object][view.formId] = arrayRemove(
          parameter[object][view.formId],
          '-' + blockKey
        );
        view.formSave();
      };
      view.body.sortable(pm3);
    };
    view.formSortable();

    // context menus
    contextBlock(view.find('.card-title'), param);
    // contextSubtab(view.body.find('.subtab .button'), param);
  }

  edit();
}
